#footer {
    width: 70%;
    height: 40px;
    margin: 0 auto;
    border-top: 1px solid var(--color-solid-semitransparent);
}

.footer-items {
    height: 40px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.footer-item {
    width: fit-content;
    padding: 6px 15px;
}

.footer-item a, .footer-item span {
    color: var(--color-solid-semitransparent);
    font-size: 12px !important;
}

.footer-item a:hover {
    color: var(--color-solid);
    text-decoration: none;
}