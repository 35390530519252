.navbar {
    height: 70px;
    padding: 0 55px 0 55px !important;
    /*border-bottom: 1px solid var(--color-solid-semitransparent);*/
    background-color: var(--color-solid);
    z-index: 1;
}

.navbar-brand {
    margin-right: 0 !important;
    padding: 0 !important;
    color: var(--color-background) !important;
}

.navbar-text .icon {
    color: #ffff66;
    filter: drop-shadow(0 0 5px #ffff66);
    cursor: pointer;
    font-size: 12px;
}